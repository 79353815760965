<template>
  <div>
    <v-form ref="form">
      <div class="row mt-3">
        <input-participantes
          v-model="form.participanteCliente"
          class="col-12 col-md-4"
          :label="$t('modulos.instrumento.formulario.cliente')"
          obrigatorio
          :disabled="isOnlyView"
          :multiplos="false"
          @ok="limparSetor"
        />
        <input-setor
          v-model="form.setor"
          class="col-12 col-md-3"
          :label="$t('modulos.instrumento.formulario.setor')"
          :placeholder="$t('geral.inputs.selecione')"
          :multiplos="false"
          :disabled="!form.participanteCliente || isOnlyView"
          :participante-id="form.participanteCliente?.value"
        />
        <input-text
          v-model="form.codigoTag"
          class="col-12 col-md-3"
          :disabled="isOnlyView"
          :label="$t('modulos.instrumento.formulario.codigo')"
          obrigatorio
        />
        <input-text
          v-model="form.numeroSerie"
          class="col-12 col-md-2"
          :disabled="isOnlyView"
          :label="$t('modulos.instrumento.formulario.numero_serie')"
        />
        <input-tipo-instrumento
          v-model="form.tipoInstrumento"
          class="col-12 col-md-4"
          :label="$t('modulos.instrumento.formulario.tipo_de_instrumento')"
          obrigatorio
          :multiplos="false"
          :disabled="isOnlyView"
          @ok="alterouTipoInstrumento"
        />

        <input-modelo-instrumento
          ref="modelo-instrumento"
          v-model="form.modeloInstrumento"
          class="col-12 col-md-3"
          :label="$t('modulos.instrumento.formulario.modelo')"
          obrigatorio
          :multiplos="false"
          :tipos-instrumentos="form.tipoInstrumento"
          :disabled="!form.tipoInstrumento || isOnlyView"
          @ok="associarFaixasDoModelo"
        />
        <input-text
          :value="form.marca?.text"
          :label="$t('modulos.instrumento.formulario.marca')"
          :placeholder="$t('geral.inputs.selecione')"
          class="col-12 col-md-3"
          disabled
        />

        <input-select
          v-model="form.tipoMaterial"
          class="col-12 col-md-2"
          :label="$t('modulos.instrumento.formulario.tipo_material')"
          :options="opcoes.tipoMaterial"
          :loading="loadingTipoMaterial"
          :disabled="isOnlyView"
          clearable
        />
        <input-date
          v-model="form.dataAquisicao"
          class="col-12 col-md-4"
          :label="$t('modulos.instrumento.formulario.data_aquisicao')"
          :disabled="isOnlyView"
          apenas-datas-passadas
        />
        <input-money
          v-model="form.valorInstrumento"
          type="number"
          class="col-12 col-md-8"
          :label="$t('modulos.instrumento.formulario.valor')"
          :options="optionsInputMoney"
          :disabled="isOnlyView"
        />
        <!-- <input-select
          v-model="form.participanteFornecedorCalibracaoId"
          class="col-12 col-md-4"
          :label="$t('modulos.instrumento.formulario.fornecedor_calibracao')"
          :options="opcoes.participanteFornecedores"
          :loading="loadingFornecedores"
        /> -->

        <input-date
          v-model="form.dataUltimaCalibracao"
          class="col-12 col-md-4"
          :label="$t('modulos.instrumento.formulario.data_ultima_calibracao')"
          nudge="-130px"
          transition="''"
          :disabled="dataUltimaCalibracaoDesabilitada || isOnlyView"
          apenas-datas-passadas
          :data-referencia="form.dataAquisicao"
          :mensagem-erro="
            $t('modulos.instrumento.validacoes.data_ultima_calibracao')
          "
        />
        <input-text
          v-model="form.frequenciaCalibracaoEmMeses"
          class="col-12 col-md-4"
          :label="
            $t('modulos.instrumento.formulario.frequencia_calibracao_meses')
          "
          :regras-personalizadas="[validacaoNumeroNegativo]"
          obrigatorio
          :mascara="'##########'"
          :disabled="isOnlyView"
        />
        <input-date
          :key="keyAtualizaProximaCalibracao"
          v-model="form.proximaCalibracao"
          class="col-12 col-md-4"
          :label="$t('modulos.instrumento.formulario.proxima_calibracao')"
          apenas-datas-futuras
          disabled
        />
        <input-radio
          v-model="form.flagVerificacaoIntermediaria"
          :divider="false"
          class="col-4"
          :options="opcoes.boleano"
          :disabled="isOnlyView"
          label="Passível de Verificação Intermediária"
        />
        <input-date
          v-model="form.dataUltimaVerificacao"
          class="col-12 col-md-2"
          :label="$t('modulos.instrumento.formulario.data_ultima_verificacao')"
          nudge="-130px"
          transition="''"
          :disabled="!form.flagVerificacaoIntermediaria || isOnlyView"
          apenas-datas-passadas
        />
        <input-text
          v-model="form.frequenciaVerificacaoEmMeses"
          :disabled="!form.flagVerificacaoIntermediaria || isOnlyView"
          :obrigatorio="form.flagVerificacaoIntermediaria"
          class="col-12 col-md-3"
          :label="
            $t('modulos.instrumento.formulario.frequencia_verificacao_meses')
          "
          :mascara="'##########'"
          :regras-personalizadas="form.flagVerificacaoIntermediaria ? [validacaoNumeroNegativo] : []"
        />
        <div />
        <input-date
          :key="keyAtualizaProximaVerificacao"
          v-model="form.proximaVerificacao"
          class="col-12 col-md-3"
          :label="$t('modulos.instrumento.formulario.proxima_verificacao')"
          apenas-datas-futuras
          disabled
        />
        <input-radio
          v-model="form.flagPadrao"
          :divider="false"
          class="col-2"
          :options="opcoes.boleano"
          :label="$t('modulos.instrumento.formulario.instrumento_padrao')"
          :disabled="isOnlyView"
        />
        <input-radio
          v-model="form.flagUtilizaLeitorOCR"
          class="col-2"
          :divider="false"
          :options="opcoes.boleano"
          :disabled="
            !form.flagPadrao ||
              !$store.getters['Autenticacao/participanteLogadoConta'] ||
                isOnlyView
          "
          :label="$t('modulos.instrumento.formulario.utiliza_ocr')"
        />
        <input-text
          v-model="form.observacao"
          class="col-12 col-md-8"
          :max="2000"
          :label="$t('modulos.instrumento.formulario.observacoes')"
          :disabled="isOnlyView"
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import {
  InputSetor,
  InputTipoInstrumento,
  InputModeloInstrumento,
  InputParticipantes,
} from '@components/inputs';
import ParticipanteService from '@common/services/cadastros/ParticipanteService.js';
import ModeloInstrumentoService from '@common/services/cadastros/ModeloInstrumentoService.js';
import TipoMaterialService from '@common/services/cadastros/TipoMaterialService.js';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import { EnumModel } from '@common/models/geral/EnumModel';
import helpers from '@common/utils/helpers';
export default {
  components: {
    InputSetor,
    InputTipoInstrumento,
    InputModeloInstrumento,
    InputParticipantes,
    // InputMarca,
  },
  props: {
    form: {},
    dataUltimaCalibracaoDesabilitada: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      optionsInputMoney: {
        locale: 'pt-BR',
        prefix: '',
        suffix: '',
        length: 18,
        precision: 2,
      },
      conteudo: null,
      conteudoAntigo: null,
      opcoes: {
        participanteClientes: [],
        participanteFornecedores: [],
        boleano: helpers.BoleanoEnum,
        tipoMaterial: [],
      },
      primeiraVez: true,
      loadingMarcas: true,
      loadingCliente: true,
      loadingTipoMaterial: true,
      loadingFornecedores: true,
      keyAtualizaProximaCalibracao: 1,
      keyAtualizaProximaVerificacao: 50,
      isOnlyView: false
    };
  },
  watch: {
    'form.dataUltimaCalibracao'() {
      if (
        this.form.dataUltimaCalibracao &&
        this.form.frequenciaCalibracaoEmMeses
      )
        this.calculaProximaData(
          this.form.dataUltimaCalibracao,
          this.form.frequenciaCalibracaoEmMeses,
          'proximaCalibracao'
        );
    },
    'form.frequenciaCalibracaoEmMeses'() {
      if (
        this.form.dataUltimaCalibracao &&
        this.form.frequenciaCalibracaoEmMeses
      )
        this.calculaProximaData(
          this.form.dataUltimaCalibracao,
          this.form.frequenciaCalibracaoEmMeses,
          'proximaCalibracao'
        );
    },
    'form.dataUltimaVerificacao'() {
      if (
        this.form.dataUltimaVerificacao &&
        this.form.frequenciaVerificacaoEmMeses
      )
        this.calculaProximaData(
          this.form.dataUltimaVerificacao,
          this.form.frequenciaVerificacaoEmMeses,
          'proximaVerificacao'
        );
    },
    'form.frequenciaVerificacaoEmMeses'() {
      if (
        this.form.dataUltimaVerificacao &&
        this.form.frequenciaVerificacaoEmMeses
      )
        this.calculaProximaData(
          this.form.dataUltimaVerificacao,
          this.form.frequenciaVerificacaoEmMeses,
          'proximaVerificacao'
        );
    },
  },
  mounted() {
    this.isOnlyView = this.$route.params?.visualizacao
    this.listarParticipanteClientes();
    this.listarParticipanteFornecedores();
    this.listarTipoMaterial();
  },
  methods: {
    calculaProximaData(data, frequencia, proxima) {
      const date = new Date(data);
      const futureDate = new Date(
        date.setMonth(date.getMonth() + Number(frequencia))
      );
      if (proxima == 'proximaCalibracao') {
        this.form.proximaCalibracao = futureDate.toISOString();
        this.keyAtualizaProximaCalibracao++;
      }
      if (proxima == 'proximaVerificacao') {
        this.form.proximaVerificacao = futureDate.toISOString();
        this.keyAtualizaProximaVerificacao++;
      }
    },
    associarFaixasDoModelo() {
      this.form.camposAdicionais = [];
      this.$nextTick().then(() => {
        if (!this.form.modeloInstrumento?.value) return;
        this.form.instrumentoFaixa = [];
        ModeloInstrumentoService.buscar(this.form.modeloInstrumento.value)
          .then((res) => {
            this.form.valorInstrumento = res.data.valor;
            this.form.marca = new DropdownModel(res.data.marca);
            res.data.faixas.forEach((faixa) => {
              this.form.instrumentoFaixa.push({
                ...faixa,
                valor: 0,
                unidadeMedidaId: faixa.unidadeMedida?.id,
              });
            });
          })
          .finally(() => this.$emit('atualizar-tabela-faixa'));
      });
    },
    limparSetor() {
      this.form.setor = null;
    },
    limparModeloInstrumento() {
      this.atualizarModeloInstrumento();
      this.form.instrumentoFaixa = [];
      this.$refs.form.resetValidation();
      this.$emit('atualizar-tabela-faixa');
      this.$forceUpdate();
    },
    alterouTipoInstrumento() {
      if (this.primeiravez) return;
      this.form.camposAdicionais = [];
      this.form.marca = null;
      this.limparModeloInstrumento();
    },
    // alterouMarca() {
    //   if (this.primeiravez) return;
    //   this.limparModeloInstrumento();
    // },
    abrirModalTiposInstrumentos() {
      this.$refs['modal-filtro-instrumento'].abrirModal(this.form);
    },
    abrirModalModelosInstrumentos() {
      if (!this.form.tipoInstrumento.value) {
        this.toastErro(
          this.$t(`modulos.instrumento.validacoes.escolher_tipo_instrumento`)
        );
        return;
      }
      this.$refs['modal-filtro-modelo'].abrirModal(this.form);
    },
    abrirModalSetores() {
      this.$refs['modal-setores'].abrirModal(this.form);
    },
    validacaoNumeroNegativo(v) {
      if (v <= 0)
        return this.$t('modulos.instrumento.validacoes.valor_nao_negativo');

      return true;
    },
    listarParticipanteClientes() {
      ParticipanteService.listar({ flagCliente: true })
        .then((res) => {
          this.opcoes.participanteClientes = new DropdownModel(res.data.items);
        })
        .finally(() => {
          this.loadingCliente = false;
        });
    },
    listarParticipanteFornecedores() {
      ParticipanteService.listar({ flagFornecedor: true })
        .then((res) => {
          this.opcoes.participanteFornecedores = new DropdownModel(
            res.data.items
          );
        })
        .finally(() => {
          this.loadingFornecedores = false;
        });
    },
    listarTipoMaterial() {
      TipoMaterialService.listar()
        .then((res) => {
          this.opcoes.tipoMaterial = new EnumModel(
            res.data,
            'instrumentotipomaterial'
          );
        })
        .finally(() => {
          this.loadingTipoMaterial = false;
        });
    },
    atualizarModeloInstrumento() {
      this.$refs['modelo-instrumento'].conteudo = null;
    },
  },
};
</script>
