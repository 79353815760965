<template>
  <v-form ref="form">
    <div
      v-show="!this.form.tipoInstrumento || !this.form.modeloInstrumento"
      class="my-2 w-100"
    >
      <InlineMessage severity="warn">
        <div
          v-html="
            $t('modulos.instrumento.campos_adicionais.aviso_campos_adicionais')
          "
        />
      </InlineMessage>
    </div>
    <tabela-padrao-prime-vue
      ref="tabela"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      linha-hover
      sem-acoes
      sem-paginacao
      :por-pagina="-1"
      :mostrar-seletor="false"
      ajustar-altura-linha
    >
      <template v-slot:nome="{ slotProps }">
        <strong>{{ slotProps.data.nome }}
          <span v-if="slotProps.data.obrigatorio"> * </span>
          <v-tooltip
            right
            content-class="tooltip-right"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-if="slotProps.data.edicaoValor == 'SomenteModelo'"
                v-on="on"
              >
                $infoCircleOutline
              </v-icon>
            </template>
            <span>{{
              $t('modulos.instrumento.campos_adicionais.alterar_somente_modelo')
            }}</span>
          </v-tooltip>
        </strong>
      </template>
      <template v-slot:valor="{ slotProps }">
        <input-condicional-campos-adicionais
          v-model="buscarValorInput(slotProps.data).valorInput"
          :tipo-input="slotProps.data.tipoCampo"
          :opcoes="slotProps.data.itens"
          :disabled="slotProps.data.edicaoValor == 'SomenteModelo'"
          :obrigatorio="slotProps.data.obrigatorio"
          class="flex-fill input-tabela-campos-adicionais my-1"
        />
      </template>
      <template v-slot:valorPadrao="{ slotProps }">
        <span
          v-if="
            typeof slotProps.data.valorPadrao === 'boolean' ||
              slotProps.data.valorPadrao == 'true' ||
              slotProps.data.valorPadrao == 'false'
          "
          class="primary-grey-400--text"
        >
          {{
            slotProps.data.valorPadrao
              ? $t(`modulos.participantes.formulario.campos_adicionais.true`)
              : $t(`modulos.participantes.formulario.campos_adicionais.false`)
          }}
        </span>
        <span
          v-else
          class="primary-grey-400--text"
        >
          {{
            slotProps.data.valorPadrao
              ? formatarValorPadrao(slotProps.data)
              : ''
          }}
        </span>
      </template>
    </tabela-padrao-prime-vue>
  </v-form>
</template>
<script>
import InlineMessage from 'primevue/inlinemessage';
import CampoAdicionalService from '@common/services/cadastros/CampoAdicionalService';
import InputCondicionalCamposAdicionais from './inputs/InputCondicionalCamposAdicionais.vue';
import helpers from '@/common/utils/helpers';
import _ from 'lodash';
export default {
  components: {
    InputCondicionalCamposAdicionais,
    InlineMessage },
  props: { form: {} },
  data() {
    return {
      conteudo: [],
      camposAdicionaisValido: false,
      primeiraRenderizacao: true,
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('modulos.instrumento.campos_adicionais.nome'),
            sortable: false,
          },
          {
            value: 'valor',
            text: this.$t('modulos.instrumento.campos_adicionais.valor_input'),
            sortable: false,
            width: 440,
          },
          {
            value: 'valorPadrao',
            text: this.$t('modulos.instrumento.campos_adicionais.valor_padrao'),
            sortable: false,
            width: 380,
          },
        ],
      },
    };
  },
  watch: {
    conteudo: {
      handler() {
        this.form.camposAdicionais = this.conteudo;
        this.$emit(
          'validarCamposAdicionais',
          this.$refs.form.validate() && this.validarCamposObrigatorios()
        );
      },
      deep: true,
    },
    'form.tipoInstrumento': {
      handler() {
        this.listar();
      },
      deep: true,
    },
    'form.modeloInstrumento': function () {
      this.listar();
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    validarCampos: function () {
      this.$refs.form.validate();
    },
    validarCamposObrigatorios() {
      const camposObrigatorios = this.tabela.dados.filter(
        (el) => el.obrigatorio
      );
      const camposValidar = this.form.camposAdicionais.filter((ca) =>
        camposObrigatorios.some((co) => co.id == ca.value)
      );
      return camposValidar.every((el) => !!el.valorInput);
    },
    listar: async function () {
      await this.$nextTick();
      let parametros = {
        entidade: 1,
        tiposInstrumentosIds: this.form.tipoInstrumento?.value,
        modeloInstrumentoId: this.form.modeloInstrumento?.value,
      };
      if (
        !this.form.tipoInstrumento?.value ||
        !this.form.modeloInstrumento?.value
      ) {
        this.tabela.dados = [];
        return;
      }

      this.conteudo = _.cloneDeep(this.form.camposAdicionais);
      this.$store.dispatch('Layout/iniciarCarregamento');
      CampoAdicionalService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.validarCampos();
        });
    },
    buscarValorInput: function (item) {
      let obj = this.conteudo.find((conteudo) => conteudo.value == item.id);
      if (obj == null) {
        let valor = item.valorPadrao;
        if (valor && item.tipoCampo == 'ListaMultiplaEscolha') {
          if (valor.includes(',')) {
            valor = valor.split(',');
          } else {
            valor = [valor];
          }
        }
        obj = {
          value: item.id,
          text: item.nome,
          valorInput: valor,
        };
        this.conteudo.push(obj);
      }
      obj.tipoCampo = item.tipoCampo;
      obj.edicaoValor = item.edicaoValor;
      return obj;
    },
    formatarValorPadrao: function (item) {
      if (
        item.tipoCampo == 'ListaMultiplaEscolha' &&
        item.valorPadrao.includes(',')
      ) {
        let listaMultipla = item.valorPadrao.split(',');
        return listaMultipla.join(', ');
      }
      if (item.tipoCampo == 'Data') {
        return helpers.formatarDataBr(item.valorPadrao);
      }
      return item.valorPadrao;
    },
  },
};
</script>
