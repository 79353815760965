<template>
  <input-text
    v-if="tipoInput === 'Numerico'"
    v-model="conteudo"
    type="number"
    :placeholder="placeholder"
    :outlined="false"
    sem-label
    flat
    solo
    background-color="transparent"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
  />
  <input-text
    v-else-if="tipoInput === 'Texto'"
    v-model="conteudo"
    :placeholder="placeholder"
    :outlined="false"
    sem-label
    flat
    solo
    background-color="transparent"
    type="text"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
  />
  <input-date
    v-else-if="tipoInput === 'Data'"
    v-model="conteudo"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
    sem-label
    flat
    solo
    background-color="transparent"
  />
  <input-text
    v-else-if="tipoInput === 'Angulo'"
    v-model="conteudo"
    v-mask="mascaraAngulo"
    :placeholder="placeholder"
    :outlined="false"
    sem-label
    flat
    solo
    background-color="transparent"
    type="text"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
  />
  <input-select
    v-else-if="tipoInput === 'Booleano'"
    v-model="conteudo"
    :options="opcoesBooleano"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
    sem-label
    flat
    solo
    background-color="transparent"
  />
  <input-combobox-text
    v-else-if="tipoInput === 'ListaEscolhaUnica'"
    v-model="conteudo"
    somente-combo-box
    chips
    sem-label
    flat
    solo
    background-color="transparent"
    hide-details
    :options="opcoes"
    :placeholder="$t('geral.inputs.selecione')"
    :multiplo="false"
    :append-icon="'$downArrow'"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
  />

  <input-combobox-text
    v-else-if="tipoInput === 'ListaMultiplaEscolha'"
    v-model="conteudo"
    multiplo
    somente-combo-box
    sem-label
    flat
    solo
    background-color="transparent"
    hide-details
    chips
    :options="opcoes"
    :placeholder="$t('geral.inputs.selecione')"
    :append-icon="'$downArrow'"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
  />
  <input-text
    v-else
    v-model="conteudo"
    type="number"
    sem-label
    flat
    solo
    background-color="transparent"
    :obrigatorio="obrigatorio"
    :disabled="disabled"
  />
</template>
<script>
import helpers from '@/common/utils/helpers';
export default {
  name: "InputCondicionalModal",
  props: {
    value: {},
    tipoInput: { type: String, default: "Numerico" },
    opcoes: { default: () => [] },
    obrigatorio: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      conteudo: "",
      opcoesBooleano: [
        {
          text: this.$t('enums.boleano.sim'),
          value: 'true',
        },
        {
          text: this.$t('enums.boleano.nao'),
          value: 'false',
        },
      ]
    };
  },
  computed: {
    mascaraAngulo() {
      return helpers.removerMascara(this.conteudo).length > 6 ? `###°##'##"` : `##°##'##"`
    },
    placeholder() {
      return this.obrigatorio
        ? this.$t('modulos.instrumento.campos_adicionais.input_tipo_campo_obrigatorio')
        : this.$t('modulos.instrumento.campos_adicionais.input_tipo_campo');
    }
  },
  watch: {
    value: {
      handler() {
        this.conteudo = this.value;
      },
      immediate: true
    },
    conteudo: function() {
      this.$emit('input', this.conteudo);
    }
  }
};
</script>
