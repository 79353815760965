var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!this.form.tipoInstrumento || !this.form.modeloInstrumento),expression:"!this.form.tipoInstrumento || !this.form.modeloInstrumento"}],staticClass:"my-2 w-100"},[_c('InlineMessage',{attrs:{"severity":"warn"}},[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('modulos.instrumento.campos_adicionais.aviso_campos_adicionais')
        )}})])],1),_c('tabela-padrao-prime-vue',{ref:"tabela",staticClass:"mt-2",attrs:{"colunas":_vm.tabela.colunas,"dados":_vm.tabela.dados,"linha-hover":"","sem-acoes":"","sem-paginacao":"","por-pagina":-1,"mostrar-seletor":false,"ajustar-altura-linha":""},scopedSlots:_vm._u([{key:"nome",fn:function({ slotProps }){return [_c('strong',[_vm._v(_vm._s(slotProps.data.nome)+" "),(slotProps.data.obrigatorio)?_c('span',[_vm._v(" * ")]):_vm._e(),_c('v-tooltip',{attrs:{"right":"","content-class":"tooltip-right"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(slotProps.data.edicaoValor == 'SomenteModelo')?_c('v-icon',_vm._g({},on),[_vm._v(" $infoCircleOutline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('modulos.instrumento.campos_adicionais.alterar_somente_modelo')))])])],1)]}},{key:"valor",fn:function({ slotProps }){return [_c('input-condicional-campos-adicionais',{staticClass:"flex-fill input-tabela-campos-adicionais my-1",attrs:{"tipo-input":slotProps.data.tipoCampo,"opcoes":slotProps.data.itens,"disabled":slotProps.data.edicaoValor == 'SomenteModelo',"obrigatorio":slotProps.data.obrigatorio},model:{value:(_vm.buscarValorInput(slotProps.data).valorInput),callback:function ($$v) {_vm.$set(_vm.buscarValorInput(slotProps.data), "valorInput", $$v)},expression:"buscarValorInput(slotProps.data).valorInput"}})]}},{key:"valorPadrao",fn:function({ slotProps }){return [(
          typeof slotProps.data.valorPadrao === 'boolean' ||
            slotProps.data.valorPadrao == 'true' ||
            slotProps.data.valorPadrao == 'false'
        )?_c('span',{staticClass:"primary-grey-400--text"},[_vm._v(" "+_vm._s(slotProps.data.valorPadrao ? _vm.$t(`modulos.participantes.formulario.campos_adicionais.true`) : _vm.$t(`modulos.participantes.formulario.campos_adicionais.false`))+" ")]):_c('span',{staticClass:"primary-grey-400--text"},[_vm._v(" "+_vm._s(slotProps.data.valorPadrao ? _vm.formatarValorPadrao(slotProps.data) : '')+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }