<template>
  <div>
    <template>
      <div
        v-if="mostraTitulo"
        class="d-flex mb-8"
      >
        <div>
          <span class="titulo-pagina">
            {{ tituloFormulario }}
          </span>
          <div v-if="form.id">
            <v-chip
              class="mr-1"
              small
            >
              Código: {{ `${form.codigoTag} - ${form.tipoInstrumento?.text}` }}
            </v-chip>
          </div>
        </div>
      </div>
      <v-tabs
        v-model="indiceAba"
        class="abas-padrao"
        background-color="primary"
        color="primary"
      >
        <v-tab>
          {{ $t('modulos.instrumento.formulario.abas.dados_do_instrumento') }}
        </v-tab>
        <v-tab>
          {{ $t('modulos.instrumento.formulario.abas.faixas') }}
        </v-tab>
        <v-tab>
          {{ $t('modulos.instrumento.formulario.abas.campos_adicionais') }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="indiceAba">
        <v-tab-item>
          <dados-instrumento
            ref="dados-instrumento"
            :form="form"
            :data-ultima-calibracao-desabilitada="
              dataUltimaCalibracaoDesabilitada
            "
            @atualizar-tabela-faixa="atualizarTabelaFaixa"
          />
        </v-tab-item>
        <v-tab-item>
          <faixas
            ref="faixas"
            :form="form"
            :isOnlyView="isOnlyView"
            class="mt-5"
          />
        </v-tab-item>
        <v-tab-item>
          <campos-adicionais
            ref="campos-adicionais"
            :form="form"
            @validarCamposAdicionais="validarCamposAdicionais"
          />
        </v-tab-item>
      </v-tabs-items>
      <template>
        <v-divider class="my-5" />
        <div class="d-flex align-center justify-end" v-if="!isOnlyView">
          <botao-padrao
            outlined
            color="secondary"
            class="mr-2"
            @click="cancelar"
          >
            <v-icon>$mdiCancel</v-icon>
            {{
              tituloModalSalvar
                ? $t('geral.botoes.cancelar_modal')
                : $t('geral.botoes.cancelar')
            }}
          </botao-padrao>
          <botao-padrao @click="confirmacaoSalvar">
            <v-icon>$mdiContentSaveOutline</v-icon>
            {{
              this.$route.params.revisar
                ? $t('modulos.instrumento.botoes.revisar')
                : tituloModalSalvar
                  ? tituloModalSalvar
                  : $t('geral.botoes.salvar')
            }}
          </botao-padrao>
        </div>
        <div class="d-flex align-center justify-end" style="padding-bottom: 20px;" v-else>
          <botao-padrao
            outlined
            color="secondary"
            class="mr-2"
            @click="voltar"
          >
            <v-icon>$mdiArrowLeft</v-icon>
            {{ $t('geral.botoes.voltar') }}
          </botao-padrao>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import InstrumentoService from '@common/services/cadastros/InstrumentoService.js';
import { InstrumentoModel } from '@common/models/cadastros/InstrumentoModel';
import DadosInstrumento from './components/DadosInstrumento.vue';
import Faixas from './components/Faixas.vue';
import CamposAdicionais from './components/CamposAdicionais.vue';
import helpers from '@common/utils/helpers';

export default {
  components: { DadosInstrumento, Faixas, CamposAdicionais },
  props: {
    id: {
      type: String,
      default: '',
    },
    mostraTitulo: {
      type: Boolean,
      default: true,
    },
    formularioModal: {
      type: Boolean,
      default: false,
    },
    tituloModalSalvar: {
      type: String,
      default: '',
    },
    formularioModalContratoCriacao: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['validarCamposAdicionais'],
  data() {
    return {
      camposAdicionaisValido: false,
      indiceAba: 2,
      valido: false,
      form: new InstrumentoModel({}),
      intrumentoPadraoWatcher: null,
      dataAquisicaoWatcher: null,
      dataUltimaCalibracaoDesabilitada: false,
      isOnlyview: false,
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.instrumento.titulos.editar');
      return this.$t('modulos.instrumento.titulos.novo');
    },
  },
  watch: {
    id: function () {
      if (this.id) this.buscar(this.id);
    },
  },
  mounted() {
    this.isOnlyView = this.$route.params.visualizacao
    if (this.formularioModal) {
      window.addEventListener('keydown', this.salvarComAtalhoF2);
    }
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'Instrumento', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'Instrumento', 'Inserir');
    this.indiceAba = 0;
  },
  beforeDestroy() {
    if (this.formularioModal) {
      window.removeEventListener('keydown', this.salvarComAtalhoF2);
    }
  },
  methods: {
    salvarComAtalhoF2(event) {
      // 113 é o código da tecla F2
      if (event.keyCode === 113) {
        this.confirmacaoSalvar();
      }
    },
    validarCamposAdicionais: function (camposAdicionaisValidacao) {
      this.camposAdicionaisValido = camposAdicionaisValidacao;
    },
    mostrarIdInvalido: function () {
      this.toastErro(this.$t('modulos.instrumento.erros.id_invalido'));
    },
    verificarExistenciaCertificadoInstrumento() {
      if (!this.id) return;
      InstrumentoService.checkInstrumentoCertificado(this.id).then((res) => {
        this.dataUltimaCalibracaoDesabilitada = res.data;
      });
    },
    buscar: function (id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      return InstrumentoService.buscar(id)
        .then((res) => {
          this.form = new InstrumentoModel(res.data);
        })
        .catch(() => {
          this.mostrarIdInvalido();
        })
        .finally(() => {
          this.verificarExistenciaCertificadoInstrumento();
          this.valido = this.$refs['dados-instrumento'].$refs.form.validate();
          this.$store.dispatch('Layout/terminarCarregamento');

          this.intrumentoPadraoWatcher = this.$watch('form.flagPadrao', () => {
            this.form.flagUtilizaLeitorOCR = false;
          });

          this.dataAquisicaoWatcher = this.$watch(
            'form.dataAquisicao',
            (novaDataAquisicao) => {
              this.form.dataUltimaCalibracao = null;
              this.form.dataReferencia = novaDataAquisicao;
            }
          );
        });
    },
    confirmacaoSalvar: function () {
      if (!this.validarInstrumento()) return;
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    buscarDadosInstrumentoClonar: async function (id, solicitante) {
      if (id) {
        await this.buscar(id);
        this.form.camposAdicionais = [];
        this.form.id = null;
        this.form.instrumentoFaixa?.forEach((faixa) => (faixa.id = null));
      }
      this.form.participanteCliente = solicitante;
    },
    validarInstrumento() {
      this.valido = this.$refs['dados-instrumento'].$refs.form.validate();
      if (!this.valido) {
        this.indiceAba = 0;
        this.toastAlerta(
          this.$t('modulos.instrumento.validacoes.formulario_invalido')
        );
        return;
      }
      if (!this.camposAdicionaisValido) {
        this.indiceAba = 2;
        this.toastAlerta(
          this.$t(`modulos.instrumento.erros.verificar_campos_adicionais`)
        );
        this.valido = false;
        return;
      }
      return this.valido && this.camposAdicionaisValido;
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      InstrumentoService.salvar(this.form.request)
        .then((res) => {
          if (!this.form.id) {
            this.toastSucesso(this.$t(`modulos.instrumento.cadastro_sucesso`));
          } else {
            this.toastSucesso(this.$t(`modulos.instrumento.edicao_sucesso`));
          }
          if (this.formularioModal) {
            this.$emit('atualiza-instrumento', this.form);
          }
          if (this.formularioModalContratoCriacao) {
            this.$emit('atualiza-listagem-instrumentos-contrato', res.data);
          }
          this.$store.dispatch('Layout/terminarCarregamento');
          this.voltar(this.form.id);
        })
        .catch((err) => {
          this.$store.dispatch('Layout/terminarCarregamento');

          if (
            err.response.data.errors[0] ==
            'codigoTagJaCadastradoParaEsseParticipante'
          ) {
            this.toastAlerta(
              this.$t(
                'modulos.instrumento.erros.codigoTagJaCadastradoParaEsseParticipante'
              )
            );
            return;
          }
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        });
    },
    voltar: function (id) {
      const retorno = this.$route.params.retorno;

      if (this.formularioModalContratoCriacao) {
        this.form = new InstrumentoModel({});
      }

      if (!this.formularioModal && !this.formularioModalContratoCriacao) {
        this.$router.push({ name: retorno || 'instrumento' });
      } else {
        this.form.id = id;
        this.$emit('fecha-modal-criacao-intrumento', this.form);
      }
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.voltar();
      });
    },
    atualizarTabelaFaixa: function () {
      this.$refs.faixas?.atualizarTabela();
      this.$refs['campos-adicionais']?.listar();
    },
  },
};
</script>
